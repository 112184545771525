/*jshint esversion: 6 */
import { Calendar } from "./Calendar";
import { LeapPairs } from "./Calendar";

/**
 * @constructor
 * @extends Calendar
 * @class HumanCalendar "Gregorian"
 */
export class HumanCalendar extends Calendar {
	constructor(currentHour=0, currentDay=1, currentWeek=1, currentMonth=1, currentYear=1){
		super(currentHour, currentDay, currentWeek, currentMonth, currentYear);

		this.hoursInDay = 24;
		this.daysInWeek = 7;
		this.weeksInMonth = 4;
		this.monthsInYear = 12;
		this.daysAddedEachMonth = [3, 0, 3, 2, 3, 2, 3, 3, 2, 3, 2, 3];

		this.leapYearModif = [new LeapPairs(2, 1)];
		//this.rationalize();
	}

	isLeapYear( iYear ){
		if( iYear % 4 === 0 && ( iYear % 100 !== 0 || iYear % 400 === 0 ) ){
			return true;
		}
		return false;
	}
}