/*jshint esversion: 6 */
import { DdMm } from "./DateFormats";
import { DdMmYyyy } from "./DateFormats";

/** 
 * @abstract
 * @constructor
 * @class Calendar
*/
export class Calendar {
	//To be defined in the extending class
	//All but daysAddedEachMonth and leapYearModif are defined as simple numbers
	//daysAddedEachMonth is an array of number of days added each month, the index is the month to which the days are added
	//leapYearModif is an array of leapPairs
	//hoursInDay;
	//daysInWeek;
	//weeksInMonth;
	//monthsInYear;
	//daysAddedEachMonth = [];
	//leapYearModif = [];

	//Current date of the calendar
	//currentHour; //of the day
	//currentDay; //of the week
	//currentWeek; //of the month
	//currentMonth; //of the year
	//currentYear; //since some random event

	constructor(currentHour = 0, currentDay = 1, currentWeek = 1, currentMonth = 1, currentYear = 1) {
		if (this.constructor === Calendar) {
			throw new Error("Abstart classes can't be initiated !");
		}
		this.currentHour = currentHour;
		this.currentDay = currentDay;
		this.currentWeek = currentWeek;
		this.currentMonth = currentMonth;
		this.currentYear = currentYear;
	}

	getCommonDaysInMonth() {
		return this.daysInWeek * this.weeksInMonth;
	}

	getDaysInMonth(iYear, iMonth) {
		return this.getCommonDaysInMonth() + this.getAddedDaysForMonthAndYear(iYear, iMonth);
	}

	getDaysInYear(iYear) {
		let iResult = 0;
		for (let iMonthIndex = 1; iMonthIndex <= this.monthsInYear; ++iMonthIndex) {
			let iDaysInMonth = this.getDaysInMonth(iYear, iMonthIndex);
			iResult += iDaysInMonth;
		}
		return iResult;
	}

	getDayOfTheMonth() {
		return (this.currentWeek - 1) * this.daysInWeek + this.currentDay;
	}

	getDayOfTheYear() {
		let iTotalDays = 0;
		for (let i = 1; i < this.currentMonth; ++i) {
			iTotalDays += this.getDaysInMonth(this.currentYear, i);
		}
		iTotalDays += this.getDayOfTheMonth();
		return iTotalDays;
	}

	getDaysSinceStartOfCalendar() {
		let iTotalDays = 0;
		for (let iYearIndex = 1; iYearIndex < this.currentYear; ++iYearIndex) {
			iTotalDays += this.getDaysInYear(iYearIndex)
		}
		iTotalDays += this.getDayOfTheYear();
		return iTotalDays;
	}

	computeDate(iDaysSinceStart) {
		let iMonthsSinceStart = 0;
		let iAddedDaysFromMonths = 0;
		let iYear = 0;
		let bDone = false;
		while (!bDone) {
			++iYear;
			for (let iMonth = 1; iMonth <= this.monthsInYear; ++iMonth) {
				if (iAddedDaysFromMonths >= iDaysSinceStart) {
					bDone = true;
					break;
				}
				iAddedDaysFromMonths += this.getDaysInMonth(iYear, iMonth);
				++iMonthsSinceStart;
			}
		}


		let iCurrentMonth = iMonthsSinceStart % this.monthsInYear;
		if (iCurrentMonth === 0) {
			iCurrentMonth = this.monthsInYear;
		}

		const iCurrentYear = Math.ceil(iMonthsSinceStart / this.monthsInYear);

		const iCurrentMonthDay = iDaysSinceStart - (iAddedDaysFromMonths - this.getDaysInMonth(iCurrentYear, iCurrentMonth));

		const iCurrentWeek = Math.ceil(iCurrentMonthDay / this.daysInWeek);

		let iCurrentWeekDay = iCurrentMonthDay % this.daysInWeek;
		if (iCurrentWeekDay === 0) {
			iCurrentWeekDay = this.daysInWeek;
		}

		this.currentDay = iCurrentWeekDay;
		this.currentWeek = iCurrentWeek;
		this.currentMonth = iCurrentMonth;
		this.currentYear = iCurrentYear;
	}

	toDDMMYYYY() {
		return new DdMmYyyy(this.getDayOfTheMonth(), this.currentMonth, this.currentYear);
	}

	toDDMM() {
		return new DdMm(this.getDayOfTheMonth(), this.currentMonth);
	}

	getWeekFromDayInMonth(iDayOfTheMonth) {
		let iResult = Math.ceil(iDayOfTheMonth / this.daysInWeek);
		return iResult;
	}

	setFromDdMm(date) {
		if( date.month > 0 )
		{
			this.currentDay = date.day % this.daysInWeek;
			if (this.currentDay === 0) {
				this.currentDay = this.daysInWeek;
			}
		}
		else
		{
			this.month = 0;
		}

		this.currentWeek = this.getWeekFromDayInMonth(date.day);
		this.currentMonth = date.month;
	}

	setFromDdMmYyyy(date) {
		this.setFromDdMm(date)
		this.currentYear = date.year;
	}

	/**
	 * @abstract
	 */
	isLeapYear(iYear) {
		throw new Error("Abstract method can't be called !");
	}

	isLeapMonth(iYear, iMonth) {
		let bLeapMonth = false;
		if (this.isLeapYear(iYear)) {
			this.leapYearModif.forEach(e => {
				if (e.month === iMonth) {
					bLeapMonth = true;
				}
			});
		}
		return bLeapMonth;
	}

	isDateValid() {
		if(this.currentDay === 0 || this.currentWeek === 0 || this.iCurrentMonth === 0 || this.currentYear === 0) {
			return false;
		}

		if(this.currentMonth > this.monthsInYear) {
			return false;
		}

		const iDayOfTheMonth = this.getDayOfTheMonth();
		if (iDayOfTheMonth > this.getDaysInMonth(this.year, this.currentMonth)) {
			return false;
		}

		return true;
	}

	getAddedDaysForMonthAndYear(iYear, iMonth) {
		if (0 >= iYear || 0 >= iMonth) {
			return null;
		}
		let res = this.daysAddedEachMonth[iMonth - 1];
		if (this.isLeapMonth(iYear, iMonth)) {
			this.leapYearModif.forEach(e => {
				if (e.month === iMonth) {
					res += e.daysAdded;
				}
			});
		}
		return res;
	}

	equalizeToParallel(oCalendar) {
		const iDaysSinceStart = oCalendar.getDaysSinceStartOfCalendar();
		this.computeDate(iDaysSinceStart);
	}
}

/**
 * @class LeapPairs
 * @constructor
 * This class is used to reprenst pairs of months and days added to this month in the case of a leap year.
 * month represents the number of the month in the year : November = 11
 */
export class LeapPairs {
	//month;
	//daysAdded; //or substracted
	constructor(month, daysAdded) {
		this.month = month;
		this.daysAdded = daysAdded;
	}
}