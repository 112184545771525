/*jshint esversion: 6 */
import { Pentagram } from './img/svg/Pentagram.js';
import './App.css';
import { HumanCalendar } from './calendars/HumanCalendar';
import { DemonCalendar } from './calendars/DemonCalendar';
import { DdMm, DdMmYyyy, EDateFormat } from './calendars/DateFormats.js';
import React from 'react';

function App() {
	return (
		<div className="App">
			<header className="App-header">
				<h6>
				Website still in construction...
				</h6>
				{Pentagram()}
				<p>
				Enter the date {'\u2764'}
				</p>
				<DateForm/>
			</header>
		</div>
	);
}

class DateForm extends React.Component {
	constructor(){
		super();
		
		this.state = {
			placeHolder : "06/06/1999",
			error : true,
			eDateFormat : EDateFormat.DEFAULT,
			date : new DdMm()
		}; 
		
		this.parse = this.parse.bind(this);
	}

	parse(event){
		let tmpDate = DdMmYyyy.fromString(event.target.value);
		let eCurrentDateFormat = EDateFormat.DDMMYYYY;
		if( tmpDate === -1 )
		{
			eCurrentDateFormat = EDateFormat.DDMM;
			tmpDate = DdMm.fromString(event.target.value);
		}
		if(tmpDate === -1){
			this.setState({error : true});
		}else{
			this.setState({error : false});
			this.setState({date : tmpDate});
			this.setState({eDateFormat: eCurrentDateFormat});
		}
	}

	result(){
		if(this.state.error){
			return(<p>Date format must be DD/MM or DD/MM/YY...</p>);
		}
		var oHumanCalendar = new HumanCalendar();
		switch(this.state.eDateFormat) {
			case EDateFormat.DDMM:
				oHumanCalendar.setFromDdMm(this.state.date);
				break;
			case EDateFormat.DDMMYYYY:
				oHumanCalendar.setFromDdMmYyyy(this.state.date);
				break;
			default:
				return <p>{"Invalid date format"}</p>;
		}
		if(!oHumanCalendar.isDateValid()) {
			return <p>{"Invalid date"}</p>;
		}
		var oDemonCalendar = new DemonCalendar();
		oDemonCalendar.equalizeToParallel(oHumanCalendar);
		let oResult;
		switch(this.state.eDateFormat) {
			case EDateFormat.DDMM:
				oResult = <p>{oDemonCalendar.toDDMM().toString()}</p>;
				break;
			case EDateFormat.DDMMYYYY:
				oResult = <p>{oDemonCalendar.toDDMMYYYY().toString()}</p>;
				break;
			default:
				oResult = <p>{"Invalid date format"}</p>;
		}
		return(oResult);
	}

	input(){
		return(
		<form>
			<input type={Text.toString()} placeholder={this.state.placeHolder} onChange={this.parse}/>
		</form>);
	}

	render(){
		return(<div>{this.input()}{this.result()}</div>);
	}
}

export default App;
