/*jshint esversion: 6 */
import { Calendar } from "./Calendar";
import { LeapPairs } from "./Calendar";

/**
 * @constructor
 * @extends Calendar
 * @class DemonCalendar
 */
export class DemonCalendar extends Calendar{
	constructor(currentHour=0, currentDay=1, currentWeek=1, currentMonth=1, currentYear=1){
		super(currentHour, currentDay, currentWeek, currentMonth, currentYear);

		this.hoursInDay = 51;
		this.daysInWeek = 7;
		this.weeksInMonth = 4;
		this.monthsInYear = 14;
		this.daysAddedEachMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -27];

		this.leapYearModif = [new LeapPairs(14, 1)];
		//this.rationalize();
	}

	isLeapYear( iYear ){
		if( iYear % 4 === 0 && ( iYear % 100 !== 0 || iYear % 400 === 0 ) ){
			return true;
		}
		return false;
	}
};